<template>
<div v-if="numItems === 0">
  <div class="wrapper">
  <h1>Cart is Empty</h1>
  </div>
</div>
<div v-else>

<div class="wrapper">
  <h1>Your Cart</h1>
  <div class="products">
    <div class="product" v-for="product in cart" :key="product.id">
      <div class="image">
        <img :src="'/images/products/'+product.image">
      </div>
      <div class="info">
        <div class="inner">
		<div class="name"><h1>{{product.name}}</h1></div>
        <div class="price"><h1>{{product.price}}</h1></div>
        </div>
      <button class="auto" @click="removeFromCart(product)">Remove</button>
      </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Cart',
  computed: {
    cart() {
      return this.$root.$data.cart;
    },
	numItems() {
      return this.$root.$data.cart.length;
	},
  },
  methods: {
    removeFromCart(product) {
      let index = this.$root.$data.cart.indexOf(product);
      this.$root.$data.cart.splice(index, 1)
	},
  },
}
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.top-section {
  margin-top: 20px;
  
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  
}

.inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.products {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 50px;
  width: 50%;
  min-width: 500px;
  border: 1px solid #5D6063;
  background: #f3f3f3;
}

.product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 25px;
  padding: 15px;
  width: 100%;
  background: #FFF;
}

.product img {
  height: 150px;
  width: 120px;
  object-fit: cover;
}

.product .image {
  display: flex;
  margin-bottom: 5px;
  margin-left: 25px;
}

.image {
  float: left;
}



.info {
  display: flex;
  flex-direction: column;
  color: #000;
  padding: 10px 30px;
  width: 100%;
  
}

.info h1 {
  font-size: 20px;
}

.info h2 {
  font-size: 14px;
  color: #838383;
}


.price {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.name {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.remove-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

button {
  height: 30px;
  background: #c3c3c3;
  color: white;
  border: none;
}

.auto {
  margin-left: auto;
}
</style>
